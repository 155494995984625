export class CookieUtils {
    /**
     * Sets a cookie with a specified name, value, and expiration period in days.
     * The cookie will be accessible across the entire domain.
     * 
     * @param name - The name of the cookie.
     * @param value - The value to store in the cookie.
     * @param days - The number of days until the cookie expires.
     */
    static setCookie(name: string, value: string, days: number): void {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Convert days to milliseconds
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value}; ${expires}; path=/`;
    }

    /**
     * Gets the value of a cookie with a specified name.
     * 
     * @param name - The name of the cookie to retrieve.
     * @returns The value of the cookie if it exists, or null otherwise.
     */
    static getCookie(name: string): string | null {
        const nameEQ = `${name}=`;
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();
            if (cookie.indexOf(nameEQ) === 0) {
                return cookie.substring(nameEQ.length, cookie.length);
            }
        }
        return null;
    }

    /**
     * Checks if a cookie exists by calling `getCookie(name)` and comparing its
     * result to null. This function is a convenience wrapper around `getCookie`
     * that allows you to check if a cookie exists without having to compare its
     * value to null.
     *
     * @param name The name of the cookie to check.
     * @returns True if the cookie exists, false if it does not.
     */
    static checkCookie(name: string): boolean {
        return this.getCookie(name) !== null;
    }

    /**
     * Deletes a cookie by setting its value to an empty string and setting its
     * expiration date to the past.
     *
     * @param name The name of the cookie to delete.
     */
    static deleteCookie(name: string): void {
        this.setCookie(name, '', -1); // Set the cookie with an expired date
    }
}
